import React from 'react';

import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import Navbar from './navbar';

export default function Layout({ mainClasses, title, children }) {
  const footerLinks = [
    { title: 'Terms', to: '/terms' },
    // {title: "Privacy", to: "/privacy"},
    // {title: "Security", to: "/security"},
  ];
  return (
    <div className="relative bg-gray-50 overflow-hidden">
      <Helmet
        titleTemplate="%s | Adapta Solutions Inc."
        defaultTitle="Adapta Solutions Inc."
      >
        <meta charSet="utf-8" />
        {title && <title>{title}</title>}
      </Helmet>
      <div className="relative pt-6 pb-12 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32">
        <Navbar />

        <main
          className={`mt-10 sm:mt-12 md:mt-14 lg:mt-16 xl:mt-20 ${mainClasses}`}
        >
          {children}
        </main>
      </div>

      <div className="bg-brandbg">
        <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center md:order-2">
            <a
              href="https://grt-status.adapta.ca/"
              className="text-gray-400 hover:text-gray-500"
            >
              System Status
            </a>
          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p className="text-center text-base leading-6 text-gray-400">
              &copy; Adapta Solutions Inc. All rights reserved.
            </p>
            <p className="text-sm text-gray-400">
              {footerLinks.map((x, i) => {
                return (
                  <React.Fragment key={`footer-${i}`}>
                    {i !== 0 && ' | '}
                    <Link
                      to={x.to}
                      className="hover:text-gray-300 focus:text-gray-300"
                    >
                      {x.title}
                    </Link>
                  </React.Fragment>
                );
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
