import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Transition } from '@tailwindui/react';

import LogoSymbolInlineSvg from '../images/logo-symbol.inline.svg';

export default function Navbar({ children }) {
  const menuItems = [
    { text: 'About', link: '/about' },
    { text: 'Products', link: '/products' },
    { text: 'Plans', link: '/plans' },
    // {text: "Features", link: "/products"},
    // {text: "Company", link: "/products"},
    { text: 'Contact Us', link: '/contact' },
  ];
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6">
        <nav className="relative flex items-center justify-between sm:h-10 md:justify-center">
          <div className="flex items-center flex-1 md:absolute md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <Link to="/" aria-label="Home">
                <img
                  className="h-8 w-auto sm:h-10"
                  src={LogoSymbolInlineSvg}
                  alt="Logo"
                />
              </Link>
              {menuItems.length > 0 && (
                <div className="-mr-2 flex items-center md:hidden">
                  <button
                    type="button"
                    onClick={() => {
                      setIsMobileMenuOpen(true);
                    }}
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    id="main-menu"
                    aria-label="Main menu"
                    aria-haspopup="true"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>
          {menuItems.length > 0 && (
            <div className="hidden md:flex md:space-x-10">
              {menuItems.map((item, i) => {
                return (
                  <Link
                    key={`menu-nav-${i}`}
                    to={item.link}
                    activeClassName="text-gray-800"
                    className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                  >
                    {item.text}
                  </Link>
                );
              })}
            </div>
          )}
          {/* <div className="hidden md:absolute md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
          <span className="inline-flex rounded-md shadow">
            <a href="#" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-brandblue bg-white hover:text-indigo-500 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-700 transition duration-150 ease-in-out">
              Log in
            </a>
          </span>
        </div> */}
        </nav>
      </div>

      {/* 
      Mobile menu, show/hide based on menu open state.
    */}
      <Transition
        show={isMobileMenuOpen}
        enter="transition duration-150 ease-out transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-100 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md">
            <div
              className="rounded-lg bg-white shadow-xs overflow-hidden"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src={LogoSymbolInlineSvg}
                    alt=""
                  />
                </div>
                <div className="-mr-2">
                  <button
                    onClick={() => {
                      setIsMobileMenuOpen(false);
                    }}
                    type="button"
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                    aria-label="Close menu"
                  >
                    <svg
                      className="h-6 w-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {menuItems.length > 0 && (
                <div className="px-2 pt-2 pb-3">
                  {menuItems.map((item, i) => {
                    return (
                      <Link
                        key={`mobile-menu-nav-${i}`}
                        to={item.link}
                        className={`${
                          i !== 0 && 'mt-1'
                        } block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out`}
                        role="menuitem"
                      >
                        {item.text}
                      </Link>
                    );
                  })}
                </div>
              )}
              {/* <div>
              <a href="#" className="block w-full px-5 py-3 text-center font-medium text-brandblue bg-gray-50 hover:bg-gray-100 hover:text-indigo-700 focus:outline-none focus:bg-gray-100 focus:text-indigo-700 transition duration-150 ease-in-out" role="menuitem">
                Log in
              </a>
            </div> */}
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
}
